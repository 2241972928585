import React, { PureComponent } from 'react'
import classnames from 'classnames'
import Equalizer from './Equalizer'
import { Link } from 'gatsby'

import arrow from '../images/cta-arrow.svg'

class InnovatorsColumn extends PureComponent {
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
      .filter(node => node)
  }

  render() {
    return (
      <>
        <Equalizer
          byRow={false}
          nodes={() => this.getNodesForEqualizer('descriptionNode')}
          enabled={() => (this.media ? this.media.matches : true)}
          forceGroupItemsCount
          groupItemsCount={3}
        >
          <div className="lg:flex lg:flex-wrap lg:-mx-4">
            {this.props.innovators.map(
              ({ uid, description, image, link }, index) => (
                <div
                  key={index}
                  className={classnames(
                    'mb-16 lg:mb-0 lg:w-1/3 flex-shrink-0 flex-no-grow mt-16 px-4',
                    {
                      'lg:mr-12':
                        (index !== this.props.innovators.length % 3) === 0,
                    }
                  )}
                >
                  <div className="lg:h-24 mb-8 lg:flex lg:items-center">
                    <Link
                      to={`/longevity-innovators`}
                      className="block no-underline flex-grow"
                    >
                      <img
                        src={image}
                        alt=""
                        className={classnames('block max-h-24 max-w-80%')}
                      />
                    </Link>
                  </div>
                  <p
                    className="leading-normal font-sans"
                    ref={node => (this[`descriptionNode${index}`] = node)}
                  >
                    <Link
                      to={`/longevity-innovators`}
                      className="block no-underline text-blue-dianne"
                    >
                      {description}
                    </Link>
                  </p>
                  {link && link._linkType === 'Link.web' && (
                    <p className="mt-8 font-sans text-gothic text-sm">
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="no-underline hover:underline text-gothic uppercase"
                      >
                        Read more
                      </a>
                    </p>
                  )}
                </div>
              )
            )}
            {this.props.paginationArrowLink && (
              <div className="mt-16 px-4 flex-grow relative hidden lg:block">
                <div className="pagination-arrow__outer">
                  <div className="pagination-arrow__inner flex items-center justify-center">
                    <Link
                      to={this.props.paginationArrowLink}
                      className="block w-24 h-24 flex items-center justify-center rounded-full border border-transparent hover:border-blue-dianne"
                    >
                      <img src={arrow} alt="" className="h-8" />
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Equalizer>
      </>
    )
  }
}

export default InnovatorsColumn
