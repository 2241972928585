import React from 'react'
import { graphql, Link } from 'gatsby'
import classnames from 'classnames'
import Layout from '../components/layout'
import SEO from '../components/seo'
import InnovatorsColumn from '../components/InnovatorsColumn'
import PageHeader from '../components/PageHeader'

import arrow from '../images/cta-arrow.svg'

export const query = graphql`
  {
    allFile(filter: { name: { glob: "innovator__logo--*" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluidGrayscale: fluid(
              maxWidth: 414
              quality: 100
              grayscale: true
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

class LongevityInnovators extends React.PureComponent {
  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
  }

  render() {
    const { pageData: page, innovators } = this.props.pageContext
    const { allFile } = this.props.data

    const files = allFile.edges.map(file => file.node)

    const images = innovators.map(innovator => {
      const foundFile = files.find(
        file => file.name === `innovator__logo--${innovator.index}`
      )
      if (foundFile) {
        return foundFile.childImageSharp.fluidGrayscale.src
      }

      return innovator.innovator_logo.url
    })

    const { props } = this

    const hasPreviousPage = props.pageContext.currentPage > 1
    const hasNextPage =
      props.pageContext.currentPage < props.pageContext.numPages

    return (
      <Layout>
        <SEO title="Longevity Start-ups" keywords={[`longevity`]} />
        <PageHeader titles={[page.title]} descriptions={[page.description]} />
        <div className="container lg:max-w-xl mx-auto mb-24 lg:mb-32">
          <div className={classnames('mt-16', {})}>
            <InnovatorsColumn
              innovators={innovators.map((innovator, index) => ({
                uid: index,
                description: innovator.innovator_description,
                image: this.props.pageContext.isGrayscale
                  ? images[index]
                  : innovator.innovator_logo.url,
                link: innovator.innovator_link,
              }))}
              paginationArrowLink={
                this.props.pageContext.numPages >
                this.props.pageContext.currentPage
                  ? `/longevity-start-ups/${this.props.pageContext.currentPage +
                      1}`
                  : undefined
              }
            />
          </div>
          {this.props.pageContext.numPages && (
            <ul
              id="pagination"
              className="list-reset flex items-center font-sans my-16 lg:my-24 flex-wrap justify-start px-4 lg:px-0"
            >
              <li className="w-1/3">
                <Link
                  to={`/longevity-start-ups${
                    this.props.pageContext.currentPage === 2
                      ? ''
                      : `/${this.props.pageContext.currentPage - 1}`
                  }`}
                  className={classnames(
                    'font-serif text-base text-blue-dianne flex items-center no-underline',
                    {
                      invisible: !hasPreviousPage,
                    }
                  )}
                >
                  <img
                    src={arrow}
                    alt=""
                    className="mr-4 lg:mr-8 h-6 svg-mirror"
                  />
                  {/* <span className="cta-ie11-fix">Previous</span>{' '} */}
                </Link>
              </li>
              <li style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <p className="font-serif text-blue-dianne">
                  Page {this.props.pageContext.currentPage} of{' '}
                  {this.props.pageContext.numPages}
                </p>
              </li>
              <li className="w-1/3">
                <Link
                  to={`/longevity-start-ups/${this.props.pageContext
                    .currentPage + 1}`}
                  className={classnames(
                    'font-serif text-base text-blue-dianne flex items-center justify-end no-underline',
                    {
                      invisible: !hasNextPage,
                    }
                  )}
                >
                  {/* <span className="cta-ie11-fix">Next</span>{' '} */}
                  <img src={arrow} alt="" className="ml-4 g:ml-8 h-6" />
                </Link>
              </li>
            </ul>
          )}
        </div>
      </Layout>
    )
  }
}

export default LongevityInnovators
